/* eslint-disable class-methods-use-this */
import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import matrix from '@studiometa/js-toolkit/utils/css/matrix';
import { isDev } from '../config';

/**
 * Cms class.
 */
export default class Cms extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'Cms',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'l xl xxl',
      },
    },
    refs: ['cursor'],
  };

  /**
   * Mounted
   */
  mounted() {
    this.isEnter = false;
  }

  /**
   * On mouse enter
   */
  onMouseenter() {
    this.isEnter = true;
  }

  /**
   * On mouse leave
   */
  onMouseleave() {
    this.isEnter = false;
  }

  /**
   * On mouse move
   *
   * @param {object} props The JS Toolkit "Base" moved props
   */
  moved(props) {
    if (!this.isEnter || !this.$refs.cursor) {
      return;
    }

    this.$refs.cursor.style.transform = matrix({
      translateX: props.event.layerX - 70,
      translateY: props.event.layerY - 70,
    });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
