import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { isDev } from '../config';

/**
 * HeaderMobile class.
 */
export default class HeaderMobile extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'HeaderMobile',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'xxs xs s m',
      },
    },
    refs: [
      'container',
      'topBar',
      'shadowTop',
      'menuToggle',
      'aside',
      'shadowMenu',
      'menuItems[]',
      'subMenuBtnOpen[]',
      'subMenuBlock[]',
      'subMenuBtnClose[]',
    ],
  };

  /**
   * Mounted
   */
  mounted() {
    // Elements
    this.$html = document.documentElement;

    // Variables
    this.topBarHeight = this.$refs.topBar.clientHeight;

    // Init
    this.$refs.aside.style.top = `${this.headerHeight}px`;
    this.$refs.shadowMenu.style.top = `-${this.headerHeight - this.topBarHeight}px`;
  }

  /**
   * Header height
   */
  get headerHeight() {
    return this.$refs.container.clientHeight;
  }

  /**
   * Resized
   */
  resized() {
    this.$refs.aside.style.top = `${this.headerHeight}px`;
  }

  /**
   * Handle SubMenu button open click
   */
  onSubMenuBtnOpenClick(event) {
    this.$log('onSubMenuBtnOpenClick');

    this.$el.classList.add('is-submenu-open');
    const $li = event.target.closest('.menu-mobile__item');

    if (!$li) {
      return;
    }

    $li.classList.add('is-open');

    const $ul = $li.closest('.menu-mobile__list');

    if (!$ul) {
      return;
    }

    $ul.classList.remove('overflow-auto');
    $ul.classList.add('overflow-hidden');
  }

  /**
   * Handle SubMenu button close click
   */
  onSubMenuBtnCloseClick(event) {
    this.$log('onSubMenuBtnCloseClick');

    const $currentLi = event.target.closest('.menu-mobile__item');

    if (!$currentLi) {
      return;
    }

    $currentLi.scrollTop = 0;

    const $parentLi = event.target.closest('.menu-mobile__list').closest('.menu-mobile__item');

    if (!$parentLi) {
      return;
    }

    this.$el.classList.remove('is-submenu-open');
    $parentLi.classList.remove('is-open');

    const $ul = $parentLi.closest('.menu-mobile__list');

    if (!$ul) {
      return;
    }

    $ul.classList.remove('overflow-hidden');
    $ul.classList.add('overflow-auto');
  }

  /**
   * Handle MenuToggle click
   */
  onMenuToggleClick() {
    this.$html.classList.toggle('is-menu-open');
    if (this.$html.classList.contains('is-menu-open')) {
      this.$refs.menuItems.forEach(($menuItem) => {
        $menuItem.classList.remove('is-open');
      });
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
