/* eslint-disable class-methods-use-this */
import 'element-closest-polyfill';
import './utils/modernizr';
import $ from 'jquery';
import gsap from 'gsap';
import Base from '@studiometa/js-toolkit';
import Accordion from '@studiometa/js-toolkit/components/Accordion';
import { isDev } from './config';
import Category from './components/Category';
import Cms from './components/Cms';
import ContactForm from './components/ContactForm';
import HeaderDesktop from './components/HeaderDesktop';
import HeaderHeight from './components/HeaderHeight';
import HeaderMobile from './components/HeaderMobile';
import Hero from './components/Hero';
import HeroSlider from './components/HeroSlider';
import MediaOuter from './components/MediaOuter';
import PostMiniature from './components/PostMiniature';
import ProductMiniature from './components/ProductMiniature';
import Search from './components/Search';
import SliderInstagram from './components/SliderInstagram';
import SliderProducts from './components/SliderProducts';
import { init as initLazyload } from './utils/lazyload';

/**
 * Main App class.
 */
class App extends Base {
  static config = {
    log: isDev(),
    name: 'App',
    components: {
      Accordion,
      Category,
      Cms,
      ContactForm,
      HeaderDesktop,
      HeaderHeight,
      HeaderMobile,
      Hero,
      HeroSlider,
      MediaOuter,
      PostMiniature,
      ProductMiniature,
      Search,
      SliderInstagram,
      SliderProducts,
      StoreLocator: () =>
        import(/* webpackChunkName: "StoreLocator" */ './components/StoreLocator'),
    },
    refs: ['shadowTop'],
  };

  /**
   * Mounted.
   *
   * @return {void}
   */
  mounted() {
    // eslint-disable-next-line dot-notation
    window['console'].log(
      '%c Made with ❤ by Studio Meta %c https://www.studiometa.fr',
      'display: inline-block; background-color: #905337; color: #ffffff; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #905337;',
      'display: inline-block; background-color: #ffff; color: #1f2126; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #905337;'
    );

    this.isScroll = false;
    this.isScrollBottom = false;
    this.isScrollTop = false;

    if (window.scrollY) {
      this.setScroll(true);
    }

    // Lazyload
    initLazyload();

    // Accordion
    $('.accordion__btn').on('click', (event) => {
      const targetSelector = ((event.target || {}).dataset || {}).target || '';

      if (!targetSelector) {
        return;
      }

      if ($(targetSelector).is(':hidden')) {
        event.target.classList.add('is-down');
      } else {
        event.target.classList.remove('is-down');
      }
    });

    // Anchor
    $('a[href^="#"]').on('click', (event) => {
      event.preventDefault();
      const anchor = (event.target || {}).getAttribute('href') || '';
      const $block = $(anchor);

      if (!$block.length) {
        return;
      }

      const offsetX = $block.offset().top || 0;

      gsap.to(document.documentElement, {
        scrollTop: !offsetX ? 0 : offsetX - 82,
        duration: 1,
        ease: 'power3.inOut',
      });
    });
  }

  /**
   * Set Scroll
   * @param {boolean} isScroll Is scroll.
   */
  setScroll(isScroll = false) {
    this.isScroll = isScroll;
    if (this.isScroll) {
      this.$el.classList.add('is-scroll');
    } else {
      this.$el.classList.remove('is-scroll');
    }
  }

  /**
   * Set Scroll direction
   * @param {boolean} isScroll Is scroll direction.
   */
  setScrollDirection(scrollDirection = '') {
    if (!scrollDirection) {
      return;
    }

    if (scrollDirection === 'bottom') {
      this.isScrollBottom = true;
      this.isScrollTop = false;
      this.$el.classList.add('is-scroll-bottom');
      this.$el.classList.remove('is-scroll-top');
    } else if (scrollDirection === 'top') {
      this.isScrollTop = true;
      this.isScrollBottom = false;
      this.$el.classList.add('is-scroll-top');
      this.$el.classList.remove('is-scroll-bottom');
    }
  }

  /**
   * Scrolled
   *
   * @param object event Scroll event.
   */
  scrolled({ y, last }) {
    if (y <= 0) {
      this.setScroll(false);
      return;
    }

    if (!this.isScroll) {
      this.setScroll(true);
    }

    if (y > last.y && !this.isScrollBottom) {
      this.setScrollDirection('bottom');
    } else if (y < last.y && !this.isScrollTop) {
      this.setScrollDirection('top');
    }
  }
}

const app = new App(document.documentElement);
app.$mount();

export default app;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
