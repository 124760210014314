// /* eslint-disable class-methods-use-this */
import Flickity from 'flickity';
import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { isDev } from '../config';

/**
 * SliderInstagram class.
 */
export default class SliderInstagram extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'SliderInstagram',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'xxs xs s',
      },
    },
    refs: ['slider', 'thumb', 'instagram[]'],
  };

  /**
   * Mounted.
   */
  mounted() {
    const { $refs } = this;

    this.isVisible = false;
    this.x = 0;
    this.y = 0;
    this.left = 0;

    this.slider = new Flickity(this.$refs.slider, {
      cellAlign: 'left',
      draggable: true,
      prevNextButtons: false,
      pageDots: false,
      on: {
        ready() {
          $refs.slider.classList.add('is-flickity-ready');
          $refs.thumb.style.width = `${100 / this.cells.length}%`;
        },
        scroll(percent) {
          $refs.thumb.style.left = `${
            (percent - (percent * (100 / this.cells.length)) / 100) * 100
          }%`;
        },
      },
    });

    const { left } = this.$refs.slider.getBoundingClientRect();
    this.left = left;

    if (this.$refs.instagram) {
      this.$refs.instagram.forEach(($instagram, index) => {
        $instagram.$slider = this;
        $instagram.sliderCount = index;
        $instagram.sliderGutter = parseInt(
          getComputedStyle(this.slider.selectedCell.element).paddingRight || '0px',
          10
        );
      });
    }
  }

  /**
   * onMouseenter
   */
  onMouseenter() {
    this.isVisible = true;
    this.$el.classList.add('is-enter');
    this.$el.classList.remove('is-leave');
  }

  /**
   * onMouseleave
   */
  onMouseleave() {
    this.isVisible = false;
    this.$el.classList.add('is-leave');
    this.$el.classList.remove('is-enter');
  }

  /**
   * Handle slider mousemove.
   *
   * @param {event} event The event.
   */
  onMousemove(event) {
    if (
      event.target.closest('.instagram-miniature__description') ||
      event.target.matches('.instagram-miniature__description')
    ) {
      return;
    }

    this.x = event.clientX;
    this.y = event.layerY;
  }

  /**
   * Resized.
   */
  resized() {
    const { left } = this.$refs.slider.getBoundingClientRect();
    this.left = left;

    if (this.$refs.instagram) {
      this.$refs.instagram.forEach(($instagram, index) => {
        $instagram.$slider = this;
        $instagram.sliderCount = index;
        $instagram.sliderGutter = parseInt(
          getComputedStyle(this.slider.selectedCell.element).paddingRight || '0px',
          10
        );
      });
    }
  }

  /**
   * Destroyed.
   */
  destroyed() {
    if (this.slider) {
      this.$refs.slider.classList.remove('is-flickity-ready');
      this.slider.destroy();
      this.slider = null;
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
