/* eslint-disable class-methods-use-this */

import Base from '@studiometa/js-toolkit';
import ObjectFitVideos from 'object-fit-videos';
import { isDev } from '../config';

/**
 * Hero class.
 */
export default class Hero extends Base {
  static config = {
    log: isDev(),
    name: 'Hero',
    refs: ['videos[]'],
  };

  /**
   * Handle videos loadeddata.
   *
   * @return {void}
   */
  onVideosLoadeddata(event) {
    ObjectFitVideos(event.target);
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
