/* eslint-disable class-methods-use-this */
import Base from '@studiometa/js-toolkit';
import useResize from '@studiometa/js-toolkit/services/resize';
import { isDev } from '../config';

const { props } = useResize();

/**
 * Search class.
 */
export default class Search extends Base {
  static config = {
    log: isDev(),
    name: 'Search',
    refs: ['searchToggle[]', 'popup'],
  };

  /**
   * Mounted
   */
  mounted() {
    this.$html = document.documentElement;
    /* eslint-disable-next-line no-underscore-dangle */
    this.headerDesktop = document.querySelector('[data-component="HeaderDesktop"]').__base__;

    this.initHeaderStyles();
  }

  /**
   * Resized
   */
  resized() {
    this.initHeaderStyles();
  }

  /**
   * Header height
   */
  get isOpen() {
    return this.$html.classList.contains('is-search-open');
  }

  /**
   * Header height
   */
  get headerHeight() {
    return ((this.headerDesktop || {}).$el || {}).clientHeight || 0;
  }

  /**
   * Init header styles
   */
  initHeaderStyles() {
    const { breakpoint } = props();

    if (['l', 'xl', 'xxl'].includes(breakpoint)) {
      const height = this.headerHeight;

      if (height) {
        this.$refs.popup.style.height = `${height}px`;
      }
    }
  }

  /**
   * Handle searchToggle click
   */
  onSearchToggleClick() {
    this.$html.classList.toggle('is-search-open');

    if (this.isOpen) {
      this.$refs.popup.setAttribute('aria-hidden', false);
    } else {
      this.$refs.popup.setAttribute('aria-hidden', true);
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
