/* eslint-disable class-methods-use-this */

import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import matrix from '@studiometa/js-toolkit/utils/css/matrix';

import { isDev } from '../config';

/**
 * PostMiniature class.
 */
export default class PostMiniature extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'PostMiniature',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'l xl xxl',
      },
    },
    refs: ['thumbnail', 'cursor'],
  };

  /**
   * mounted
   */
  mounted() {
    // Slider.
    this.$slider = null;
    this.sliderCount = 0;
    this.sliderGutter = 0;

    // Post.
    this.isEnter = false;
    this.layerX = 0;
    this.layerY = 0;
    this.x = 0;
    this.y = 0;
  }

  /**
   * onThumbnailMouseenter
   */
  onThumbnailMouseenter() {
    this.isEnter = true;
    this.$refs.thumbnail.classList.add('is-enter');
    this.$refs.thumbnail.classList.remove('is-leave');
  }

  /**
   * onThumbnailMouseleave
   */
  onThumbnailMouseleave() {
    this.isEnter = false;
    this.$refs.thumbnail.classList.add('is-leave');
    this.$refs.thumbnail.classList.remove('is-enter');
  }

  /**
   * onThumbnailMousemove
   */
  onThumbnailMousemove(event) {
    if (this.$slider) {
      return;
    }

    this.layerX = event.layerX;
    this.layerY = event.layerY;
  }

  /**
   * moved
   */
  moved(props) {
    if (!this.$slider) {
      return;
    }

    this.layerX = props.event.layerX;
    this.layerY = props.event.layerY;
  }

  /**
   * ticked
   */
  ticked() {
    if (!this.isEnter && !this.$slider) {
      return;
    }

    this.x = this.layerX;
    this.y = this.layerY;

    if (this.$slider) {
      this.x =
        this.$slider.x -
        this.$slider.left -
        this.$slider.slider.x -
        this.$el.clientWidth * this.sliderCount -
        this.sliderGutter * this.sliderCount;
      this.y = this.$slider.y;
      if (this.$slider.slider.isDragging) {
        this.x += this.$slider.slider.dragX - this.$slider.slider.dragStartPosition;
      }
    }
    if (this.$refs.cursor) {
      this.$refs.cursor.style.transform = matrix({
        translateX: this.x - this.$refs.cursor.clientWidth / 2,
        translateY: this.y - this.$refs.cursor.clientWidth / 2,
      });
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
