/* eslint-disable class-methods-use-this */
import Base from '@studiometa/js-toolkit';
import useResize from '@studiometa/js-toolkit/services/resize';
import { isDev } from '../config';

const { props } = useResize();

/**
 * HeaderMobile class.
 */
export default class HeaderHeight extends Base {
  static config = {
    log: isDev(),
    name: 'HeaderHeight',
  };

  /**
   * Mounted
   */
  mounted() {
    this.$html = document.documentElement;

    /* eslint-disable no-underscore-dangle */
    this.headerDesktop = document.querySelector('[data-component="HeaderDesktop"]').__base__;
    this.headerMobile = document.querySelector('[data-component="HeaderMobile"]').__base__;
    /* eslint-enable no-underscore-dangle */

    this.$el.style.height = `${this.height}px`;
  }

  /**
   * Get header height
   */
  get height() {
    const { breakpoint } = props();
    const mobileBreakpoints = (
      ((this.headerMobile || {}).$options || {}).activeBreakpoints || ''
    ).split(' ');
    const desktopBreakpoints = (
      ((this.headerDesktop || {}).$options || {}).activeBreakpoints || ''
    ).split(' ');

    if (mobileBreakpoints.includes(breakpoint)) {
      return parseInt(this.headerMobile.$refs.container.clientHeight, 10);
    }

    if (desktopBreakpoints.includes(breakpoint)) {
      return 151 + parseInt(this.headerDesktop.$refs.topBar.clientHeight, 10);
    }

    return 0;
  }

  /**
   * Resized
   */
  resized() {
    this.$el.style.height = `${this.height}px`;
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
